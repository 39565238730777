@import url('https://fonts.googleapis.com/css2?family=Alexandria:wght@300;400&family=Arimo:wght@400;500&family=Comfortaa:wght@300;400&family=Frank+Ruhl+Libre&family=Noto+Sans+Tamil:wght@300;400&family=Scada:ital,wght@1,700&family=Staatliches&family=Zen+Kaku+Gothic+New:wght@300;400;700&family=Zen+Maru+Gothic&family=Zilla+Slab:wght@300;400&display=swap');
.App {
  height: 100vh;
  width: 100vw;
  background-color: rgb(4, 4, 5);
 
}
html,
body {
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.TopBar {
  height: 8vh; 
  display: flex;
  justify-content: space-between;
  background-color: #2f3132;
  user-select: none;
}
.logo {
  flex: 0 0 8%; 
  overflow: hidden;
}
.logo:hover {
  cursor: pointer;
}
.logo img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  display: flex;
  overflow: hidden;
  max-width: 100%;
  max-height: 8vh;
  margin: 0;
  padding-left: 7%;
  padding-top: 0%;
  padding-bottom: 1%;
  padding-right: 4%;
}

.centerarea {
  flex: 1 1 auto; 
  display: flex;
  justify-content: center;
  align-items: center; 
  text-align: center; 
  overflow: hidden;
}
.LoginButton {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin: 0 auto;
  border-left: 1.5px solid #18191a;
  background-image: linear-gradient(to top, #7d1215, #c8222a);
}

.LoginButton img {
  max-width: 9vw;
  max-height: 100vh;
  object-fit: contain;
  transform: scale(.9);
}

.LogoutButton {
  overflow: hidden;
  text-align: right;
  justify-content: center;
  margin: 0 auto;
  border-left: 1.5px solid #18191a;
  background-image: linear-gradient(to top, #7d1215, #c8222a);
}
.LogoutButton img {
  max-width: 9vw;
  max-height: 100vh;
  object-fit: contain;
  transform: scale(.9) translateX(-1vw) translateY(-1vh);
  padding-top: 11%;
  padding-bottom: 6%;
  padding-right: 16%;
  padding-left: 8%;
}

.Data {
  height: calc(100vh-8vh);
}

.BottomBar {
  user-select: none;
  position: fixed;
  bottom: 0;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2f3132;
  padding: 0;
  margin: 0;
  height: 5vh;
  overflow: hidden;
  transition: height 0.3s ease-in-out; /* Add a smooth transition for height */
}

.BottomBar .BottomBarButton:last-child {
  border-right: 1px solid #18191a;
}

.BottomBar .HomeButton:last-child,
.BottomBar .AboutButton:last-child,
.BottomBar .ServicesButton:last-child,
.BottomBar .ContactButton:last-child,
.BottomBar .PreviousWorkButton:last-child {
  border-right: 1px solid #18191a;
}

.HomeButton,
.AboutButton,
.ServicesButton,
.ContactButton,
.PreviousWorkButton {
  text-align: center;
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative; /* Add position: relative */
  overflow: hidden;
  border-left: 1px solid #18191a; /* Add left border */
  border-right: 1px solid #18191a; /* Add right border */
}

.HomeButton:first-child {
  border-left: none;
}

.PreviousWorkButton:last-child {
  border-right: none;
}

.PreviousWorkButton:first-child,
.HomeButton:last-child {
  border-right: none;
  border-left: 1px solid #18191a; /* Add left border only for the first button */
}

.HomeButton img,
.AboutButton img,
.ServicesButton img {
  overflow: hidden;
  max-width: 80%;
  max-height: 80%;
}

.ContactButton img {
  overflow: hidden;
  max-width: 30%;
  max-height: 70%;
}

.PreviousWorkButton img {
  overflow: hidden;
  max-width: 80%;
  max-height: 70%;
}

.HomeButton::before,
.AboutButton::before,
.ServicesButton::before,
.ContactButton::before,
.PreviousWorkButton::before {
  content: "";
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to top, #7d1215, #c8222a);
  opacity: 0; /* Start with opacity 0 for the transition effect */
  transition: opacity 0.1s ease;
  z-index: -1; /* Place the pseudo-element behind the content */
}

/* Add hover styles for the pseudo-element */
.HomeButton:hover::before,
.AboutButton:hover::before,
.ServicesButton:hover::before,
.ContactButton:hover::before,
.PreviousWorkButton:hover::before {
  overflow: hidden;
  opacity: 1; /* On hover, change opacity to 1 */
  transition: opacity 0.1s ease-out;
}
.selected {
  background-image: linear-gradient(
    to top,
    #920f14,
    #db1721
  ); /* Customize the background color for the selected state */
  /* Add any other styles you want for the selected button here */
}
.container {
  display: flex;
  height: 85vh;
  justify-content: space-between;
  padding-top: 12px;
}

.box-image-container {
  display: flex;
  justify-content: center;
  /* align-items: center;
  height: 100%; */
}

.box {
  flex: 1;
  padding-top: 10px;
  padding-left: 10px;
  border: 2px solid transparent;
  border-color: #52080a;
  margin: 20px;
  background: linear-gradient(
    to top,
    rgb(38, 40, 41),
    rgb(15, 15, 15) 
  );
  transition: border-color 0.3s ease, transform 0.3s ease, opacity 0.3s ease;
  font-family: "Arimo", sans-serif;
  font-size: 40px;
  color: azure;
  opacity: 0.7;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.box-context {
  width: 100%; /* Adjust the width as needed */
  height: auto;
  max-width: 100%;
}

/* Rest of your CSS for .box, .box:hover, .header, .subheader, and .paragraph */

.box:hover {
  border-color: #920f14;
  opacity: 1;
  transform: scale(1.03);
  background: linear-gradient(
    to top,
    rgba(47, 49, 50, 1),
    rgb(18, 19, 19) 
  );
  box-shadow: 0 0 30px rgba(255, 0, 0, 0.15); /* Add a red glow effect */
}

.box img {
  width: 300px;
  height: auto;
  margin-bottom: 10px;
  max-width: 100%;
  
}
@media (min-width: 2540px) {
.header {
  font-size: 70px;
  font-weight: bold;
}
}
@media (min-width: 2540px) {
.subheader {
  font-size: 40px;
  font-style: italic;
  opacity: 0.6;
}
}
@media (min-width: 2540px) {
.paragraph {
  font-size: 50px;
  font-family: 'Zen Maru Gothic', sans-serif;
  opacity: .9;
}
}
@media (min-width: 2540px) {
  .box img {
    max-width: 100%; /* Adjust the percentage as needed */
  }
}

.card3{
  max-width: 80%;
  max-height: 80%;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    padding: 12px 0;
  }
  .box {
    margin: 10px 0;
  }
}
@media (max-width: 768px) {
  .box img {
    max-width: 80%;
  }
}
@media (max-width: 768px) {
  .box {
    width: 100%;
    margin: 20px 0;
  }
}
@media (max-width: 768px) {
  .header {
    font-size: 24px;
    font-weight: bold;
  }
}
@media (max-width: 768px) {
  .subheader {
    font-size: 18px;
    opacity: .6;
  }
}
@media (max-width: 768px) {
  .paragraph {
    font-size: 14px;
    padding-bottom: 20px;
  }
}

@media (min-width: 769px) and (max-width: 1918px) {
  .paragraph {
    font-size: 20px; 
    font-family: 'Zen Maru Gothic', sans-serif;

  }
  .header {
    font-size: 35px; 
    font-weight: bold;
  }
  .subheader {
    font-size: 25px;
    font-style: italic;
    opacity: 0.6;
  }
  .box img {
    max-width: 40%;
  }

}
@media (min-width: 1920px) and (max-width: 2539px) {
  .paragraph {
    font-size: 40px; 
    font-family: 'Zen Maru Gothic', sans-serif;

  }
  .header {
    font-size: 60px; 
    font-weight: bold;
  }
  .subheader {
    font-size: 30px;
    font-style: italic;
    opacity: 0.6;
  }
}
.paragraphAU{
  font-size: 40px;
  color: azure;
  opacity: 0.7;
  overflow: hidden;
}
.paragraphSV{
  font-size: 40px;
  color: azure;
  opacity: 0.7;
  overflow: hidden;
}

@media (max-width: 1920px) {
  .services-container:nth-child(1) img {
    max-width: 45.5%; 
    max-height: 500px; 
  }
  .services-container:nth-child(3) img {
    max-width: 55%; 
    max-height: 500px;
  }
  .services-container img {
    display: block;
    margin: 0 auto; 
    max-width: 65%; 
    max-height: 500px; 
    padding-top: 15px;
  }
.services-container {
  box-shadow: 0 0 15px rgba(255, 0, 0, 0.15);
  padding-top: 10px;
  padding-left: 10px;
  border: 2px solid transparent;
  border-color: #52080a;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 15px;
  margin-right: 15px;
  background: linear-gradient(
    to top,
    rgba(47, 49, 50, 0.8),
    rgba(47, 49, 50, 0.2)
  );
  transition: border-color 0.3s ease, transform 0.3s ease, opacity 0.3s ease;
  font-family: "Arimo", sans-serif;
  font-size: 20px;
  color: azure;
  opacity: 0.7;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  max-width: 31%;
  align-items: center;
  
}
.services-wrapper {
  display: flex;
  justify-content: space-between; /* Adjust as needed for spacing between boxes */
}
.service-header {
  padding: 15px;
  background-color: transparent;
  border-bottom: 1px solid #52080a;
  font-size: 25px;
}

.service-subheader {
  padding: 2px 15px;
  background-color: transparent;
  font-size: 24px;
}

.service-content {
  padding: 15px;
  background-color: transparent;
  margin-left: 3%;
  margin-right: 3%;
}

.service-content p {
  margin: 0;
}


.bulletpoints {
  list-style-type: disc;
  padding-left: 20px;
  margin-top: 10px;
}
.bulletpoints li {
  margin-bottom: 10px;
}
.service-section{
  margin-left: 2%;
  margin-right: 2%;
}
.services-container img {
  display: "block";
  margin: "0 auto"; 
  width: "auto";
  height: "250px";
}
.service-title{
  /* box-shadow: 0px 10px 10px -10px rgba(255, 0, 0, 0.15); */
  background: linear-gradient(
    to top,
    rgba(47, 49, 50, 0.2),
    rgba(47, 49, 50, 0.8)
);
  flex: 1;
  padding: 10px;
  border: 2px solid transparent;
  border-color: #52080a;
  transition: border-color 0.3s ease, transform 0.3s ease, opacity 0.3s ease;
  font-family: "Arimo", sans-serif;
  font-size: 20px;
  color: azure;
  opacity: 0.7;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 5px;
}
}

@media (min-width: 2560px) {
  .services-container:nth-child(1) img {
    max-width: 50.5%; 
    max-height: 500px; 
  }
  .services-container:nth-child(3) img {
    max-width: 60%; 
    max-height: 500px; 
  }
  
  .services-container {
    box-shadow: 0 0 15px rgba(255, 0, 0, 0.15);

    border: 2px solid transparent;
    border-color: #52080a;
    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
    background: linear-gradient(
      to top,
      rgba(47, 49, 50, 0.8),
      rgba(47, 49, 50, 0.2)
    );
    transition: border-color 0.3s ease, transform 0.3s ease, opacity 0.3s ease;
    font-family: "Arimo", sans-serif;
    font-size: 29px;
    color: azure;
    opacity: 0.7;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    max-width: 33%;
  }
  .services-wrapper {
    display: flex;
    justify-content: space-between; 
  }
  .service-header {
    padding: 15px;
    background-color: transparent;
    border-bottom: 1px solid #52080a;
    font-size: 35px;
  }
  
  .service-subheader {
    padding: 2px 15px;
    background-color: transparent;
    font-size: 35px;
  }
  
  .service-content {
    padding: 15px;
    background-color: transparent;
    margin-left: 3%;
    margin-right: 3%;
  }
  
  .service-content p {
    margin: 0;
  }
  
  
  .bulletpoints {
    list-style-type: disc;
    padding-left: 20px;
    margin-top: 10px;
  }
  .bulletpoints li {
    margin-bottom: 10px;
  }
  .service-section{
    margin-left: 2%;
    margin-right: 2%;
  }

  .services-container img {
    display: block;
    margin: 0 auto; 
    max-width: 70%; 
    max-height: 500px; 
    padding-top: 15px;
  }
  .service-title{
    /* box-shadow: 0px 10px 10px -10px rgba(255, 0, 0, 0.15); */
    background: linear-gradient(
      to top,
      rgba(47, 49, 50, 0.2),
      rgba(47, 49, 50, 0.8)
  );
    flex: 1;
    border: 2px solid transparent;
    border-color: #52080a;
    transition: border-color 0.3s ease, transform 0.3s ease, opacity 0.3s ease;
    font-family: "Arimo", sans-serif;
    font-size: 35px;
    color: azure;
    opacity: 0.7;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    margin-bottom: 15px;
    
  }

  }

@media (max-width: 2559px) {
  .contact-background {
    background-color: #040405;
    padding: 40px;
  }
  .contact-container {
    background-image: url('../public/slatewebsitelogo3.png');
    background-size: 25%; 
    background-repeat: no-repeat; 
    background-position: center center; 
    background-position: right 65%;
    padding-top: 1vh;
  }
  .contact-container {
    max-width: 95%;
    border: 2px solid transparent;
    border-color: #52080a;
    margin: 0 auto;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(243, 0, 0, 0.2);
    font-family: "Arimo", sans-serif;
  }

  .contact-text {
    margin-bottom: 20px;
    border-bottom: 2px solid transparent;
    padding-bottom: 20px;
    border-color: #52080a;
  }

  .contact-text h1 {
    font-size: 36px;
    color: #fff;
  }

  .contact-text p {
    font-size: 18px;
    color: #ccc;
  }

  .contact-form label {
    display: block;
    margin-bottom: 8px;
    color: #fff;
    font-size: 16px;
  }

   .contact-form input[type="text"],
  .contact-form input[type="email"],
  .contact-form textarea {
    width: 75%;
    padding: 10px;
    margin-bottom: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f5f5f5;
    color: #333;
    font-size: 16px;
    box-sizing: border-box;
    
  }
  .contact-form textarea {
    resize: vertical;
  }

  .contact-form button {
    background-image: linear-gradient(to top, #7d1215, #c8222a);
    color: #fff;
    border: none;
    padding: 12px 20px;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    font-family: "Arimo", sans-serif;
  }

  .contact-form button:hover {
    background-color: #920f14;
  }
}

@media (min-width: 2560px) {
  .contact-background {
    background-color: #040405;
    padding: 60px 0;
    margin-top: 0px;
  }

  .contact-container {
    height: 75vh;
    max-width: 95%;
    border: 2px solid transparent;
    border-color: #52080a;
    margin: 0 auto;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(243, 0, 0, 0.2);
    font-family: "Arimo", sans-serif;
    
  }

  .contact-text {
    margin-bottom: 20px;
    border-bottom: 2px solid transparent;
    padding-bottom: 20px;
    border-color: #52080a;
  }

  .contact-text h1 {
    font-size: 65px;
    margin-left: 25px;
    color: #fff;
  }

  .contact-text p {
    margin-left: 25px;
    font-size: 35px;
    color: #ccc;
  }

  .contact-form label {
    display: block;
    margin-bottom: 8px;
    color: #fff;
    font-size: 16px;
  }

  .contact-form input[type="text"],
  .contact-form input[type="email"],
  .contact-form textarea {
    width: 75%;
    padding: 10px;
    margin-bottom: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f5f5f5;
    color: #333;
    font-size: 16px;
    box-sizing: border-box;
    
  }
  .contact-container {
  background-image: url('../public/slatewebsitelogo3.png');
  background-size: 20%; 
  background-repeat: no-repeat; 
  background-position: center center; 
  background-position: right 65%;
  padding-top: 1vh;
  background-position-x: 149vh;
}
}
.contact-form{
  padding-right: 20px;
  margin-left: 5px;
  padding-top: 50px;
}
  
  .contact-form textarea {
    resize: vertical;
    
  }
  
  .contact-form button {
    margin-top: 30px;
    background-image: linear-gradient(to top, #7d1215, #c8222a);
    color: #fff;
    border: none;
    padding: 20px 35px;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    font-family: "Arimo", sans-serif;
    transition: background-color 0.3s, transform 0.15s ease-in-out, color 0.3s;

  }
  .contact-form button:hover {
    
    transform: scale(1.05); /* Scale up the button slightly on hover */
    background-image: linear-gradient(to top, #8a1317, #e0262f);
    transition: color 0.3s; /* Add color transition on hover */
  
  }
  
  .contact-form label {
    font-size: 35px; 
  }
  .contact-form input,
.contact-form textarea {
  margin-bottom: 10px; 
  
}

.aboutus-container {
  display: flex;
  height: 85vh;
  justify-content: space-between;
  padding-top: 12px;
}

.aboutus-section {
  flex: 1;
  padding: 10px;
  border: 2px solid transparent;
  border-color: #52080a;
  margin: 20px;
  background: linear-gradient(to top, rgb(38, 40, 41), rgb(15, 15, 15));
  transition: border-color 0.3s ease, transform 0.3s ease, opacity 0.3s ease;
  font-family: "Arimo", sans-serif;
  font-size: 50px;
  color: azure;
  opacity: 0.7;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.aboutus-header {
  font-size: 60x;
  font-weight: bold;
  padding: 25px;
  margin-left: 30px;
  border-bottom: 2px solid transparent ;
  border-color: #52080a;
}

.aboutus-content {
  padding: 10px;
  margin-top: 50px;
}

.aboutus-content p {
  margin: 0;
}


  .centerarea{
  font-family: 'Comfortaa', cursive;
  font-size: 35px;
  color: rgb(228, 228, 228);
  text-shadow: 8px 8px 16px rgba(0, 0, 0, 0.7);
  
}